// COLOR variables
$brand-blue: #3372dd;
$brand-light-blue: #7dd9ff;
$brand-gray: #666;
$brand-light-gray: #eee;
$white: #fff;

// FONT variables
@font-face {
	font-family: 'Proxima';
	src: url('../fonts/ProximaNova.eot') /* IE9 Compat Modes */;
	src: url('../fonts/ProximaNova.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/ProximaNova.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/ProximaNova.ttf')  format('truetype'), /* Safari, Android, iOS */ url('../fonts/ProximaNova.svg#svgFontName') format('svg'), /* Legacy iOS */ url('../fonts/ProximaNova.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */; }
