body {
	font-size: 16px;
	color: $brand-gray;
	font-family: 'Proxima'; }

a {
	color: $brand-blue; }

h1 {
	font-size: 2em;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 0.125em; }

h2 {
	font-size: 2.25em;
	margin-top: 1em;
	margin-bottom: 1.5em; }
