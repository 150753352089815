#header {
	color: $white;
	position: relative;
	// height: 300px
	background-image: linear-gradient($brand-light-blue, $brand-blue);
	padding-top: 1.5em;
	padding-bottom: 5em;
	& header {
		display: table; } }

.header-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vw;
  /* set height to pixels if you want angle to change with screen width */ }

.header-logo, .header-title {
	display: table-cell;
	vertical-align: middle; }

.header-logo {
	// display: inline-block
	background-image: linear-gradient($brand-light-blue, $brand-blue);
	border: 1px solid rgba($white, 0.8);
	border-radius: 1.5625em;
	width: 120px;
	height: 120px;
	margin: 0 auto; }

.header-title {
	padding-top: 0.5em;
	// display: inline-block
	padding-left: 1em;
	& p {
		color: rgba($white, 0.8); } }

.header-motto {
	font-size: 1.7em;
	line-height: 1.25;
	margin-top: 4em;
	margin-bottom: 4em; }

.header-download__badge {
	display: inline-block; }

.header-iphones {
	position: relative;
	margin-bottom: -15em;
	padding: 1em 2em 2em;
	margin-top: 2em; }

#svg-logo {
	height: 82px;
	width: 82px;
	margin: 0 auto;
	display: block; }
