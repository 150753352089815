.button-send {
	border: 0;
	border-radius: 2em;
	color: $white;
	background-color: $brand-blue;
	text-transform: uppercase;
	font-size: 0.75em;
	padding: 0.75em 2.5em;
	transition: all 0.2s ease;
	margin-top: 1em;
	& span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s; }
	& span:after {
		content: '\00bb';
		position: absolute;
		opacity: 0;
		top: 0;
		right: -5px;
		transition: 0.5s;
		height: 1em;
		width: 1em; }
	&:hover, &:focus {
		box-shadow: 0 0 15px rgba($brand-blue, 0.85);
 }		// transform: scale(1.1)
	&:hover span:after, &:focus span:after {
		opacity: 1;
		right: -15px; } }

