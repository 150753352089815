#features {
	padding-top: 6em;
	padding-bottom: 4em;
	& h2 {
		margin-bottom: 1em; } }

.features-item {
	background-color: lighten($brand-light-gray, 3%);
	border: 1px solid #cfcfcf;
	border-radius: 4px 4px 0 0;
	padding: 1em 1em 3em;
	margin-top: 1.5em;
	margin-bottom: 1em; }

.features-icon {
	height: 120px;
	width: 120px;
	background-color: $brand-blue;
	color: $white;
	border-radius: 50%;
	margin: 1.5em auto; }
