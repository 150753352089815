#contact {
	background-color: $brand-light-gray;
	padding-top: 1em;
	padding-bottom: 5em; }

.contact-form {
	margin-bottom: 2.5em;
	& label span {
		color: $brand-blue; } }

#contactMessage {
	resize: none; }

.contact-address__location {
	background-size: 28px 28px;
	background-repeat: no-repeat;
	list-style: none;
	margin-bottom: 1em;
	margin-left: -2em;
	padding-left: 2.5em;
	background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2028%2034.64%27%3E%0A%3Cg%3E%3Cg%3E%3Cpath%20d%3D%27M13.989%2C6.903c-3.997%2C0-7.242%2C3.253-7.242%2C7.242s3.246%2C7.25%2C7.242%2C7.25c3.997%2C0%2C7.243-3.253%2C7.243-7.242%0AC21.232%2C10.162%2C17.987%2C6.903%2C13.989%2C6.903L13.989%2C6.903z%20M13.989%2C19.659c-3.04%2C0-5.506-2.473-5.506-5.506%0Ac0-3.033%2C2.473-5.506%2C5.506-5.506c3.033%2C0%2C5.506%2C2.473%2C5.506%2C5.506C19.496%2C17.185%2C17.03%2C19.659%2C13.989%2C19.659L13.989%2C19.659z%0AM13.989%2C19.659%27%2F%3E%3Cpath%20d%3D%27M13.989%2C0c-0.163%2C0-0.333%2C0-0.496%2C0.007C6.506%2C0.248%2C0.73%2C5.676%2C0.064%2C12.629c-0.128%2C1.347-0.064%2C2.7%2C0.184%2C3.99%0Ac0%2C0%2C0.021%2C0.163%2C0.099%2C0.467c0.213%2C0.957%2C0.532%2C1.885%2C0.936%2C2.75c1.439%2C3.408%2C4.592%2C8.66%2C11.438%2C14.343%0Ac0.362%2C0.298%2C0.815%2C0.461%2C1.283%2C0.461c0.468%2C0%2C0.921-0.163%2C1.283-0.461c6.839-5.676%2C9.992-10.935%2C11.424-14.315%0Ac0.411-0.886%2C0.73-1.821%2C0.943-2.771c0.043-0.17%2C0.071-0.312%2C0.092-0.446C27.915%2C15.775%2C28%2C14.882%2C28%2C13.989%0AC27.986%2C6.279%2C21.707%2C0%2C13.989%2C0L13.989%2C0z%20M26.023%2C16.349c0%2C0.014-0.021%2C0.135-0.071%2C0.354c-0.191%2C0.836-0.468%2C1.651-0.836%2C2.459%0Ac-1.368%2C3.232-4.372%2C8.228-10.942%2C13.677c-0.064%2C0.05-0.128%2C0.064-0.177%2C0.064c-0.043%2C0-0.113-0.014-0.177-0.064%0AC7.25%2C27.383%2C4.238%2C22.387%2C2.863%2C19.134c-0.362-0.78-0.638-1.595-0.829-2.438c-0.043-0.184-0.064-0.298-0.071-0.333%0Ac0-0.014-0.007-0.028-0.007-0.043c-0.22-1.155-0.276-2.345-0.163-3.522C2.381%2C6.711%2C7.434%2C1.956%2C13.557%2C1.743%0AC20.509%2C1.502%2C26.25%2C7.087%2C26.25%2C13.996C26.25%2C14.776%2C26.172%2C15.556%2C26.023%2C16.349L26.023%2C16.349z%20M26.023%2C16.349%27%2F%3E%3C%2Fg%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E"); }

.contact-address__email {
	@extend .contact-address__location;
	background-size: 24px 20px;
	background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2028.474%2021.014%27%3E%0A%3Cg%3E%3Cg%3E%3Cpath%20style%3D%27stroke%3A%23000000%3Bstroke-width%3A0.5%3Bstroke-miterlimit%3A10%3B%27%20d%3D%27M25.893%2C0.25H2.581c-1.286%2C0-2.331%2C1.046-2.331%2C2.331%0Av15.852c0%2C1.285%2C1.045%2C2.331%2C2.331%2C2.331h23.312c1.286%2C0%2C2.331-1.046%2C2.331-2.331V2.581C28.224%2C1.296%2C27.179%2C0.25%2C25.893%2C0.25%0AL25.893%2C0.25z%20M25.893%2C1.182c0.124%2C0%2C0.245%2C0.017%2C0.36%2C0.047L14.237%2C9.932L2.22%2C1.23c0.115-0.031%2C0.236-0.048%2C0.361-0.048H25.893z%0AM27.292%2C18.433c0%2C0.771-0.627%2C1.399-1.399%2C1.399H2.581c-0.771%2C0-1.399-0.628-1.399-1.399V2.581c0-0.288%2C0.088-0.556%2C0.238-0.779%0Al12.544%2C9.083c0.081%2C0.059%2C0.177%2C0.089%2C0.273%2C0.089c0.096%2C0%2C0.192-0.03%2C0.273-0.089l12.543-9.083%0Ac0.15%2C0.223%2C0.238%2C0.491%2C0.238%2C0.78V18.433z%20M27.292%2C18.433%27%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

.contact-social {
	margin-top: 3em;
	& a {
		display: inline-block;
		margin: 0 1em 0 0;
		transition: all 0.2s ease; }
	& a img {
		height: 2em;
		width: 2em;
		display: inline-block; }
	& a:hover, & a:focus {
		opacity: 0.7;
		transform: scale(1.05); } }
