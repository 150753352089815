#svg-fb-logo {
	height: 60px;
	width: 60px;
	display: block;
	margin: 0 auto;
	position: relative;
	top: 30px; }

#svg-guess-icon {
	@extend #svg-fb-logo;
	height: 68px;
	width: 68px;
	top: 26px; }

#svg-iphone-icon {
	@extend #svg-fb-logo; }
